.allApp {
  width: 98%;
}

    .header {
      display: flex;
    } 

        .title {
          flex: 3;
          text-align: center;
        }

        .plays {
          flex: 12;
        }

    .script {
    }

.box {
  float: left;
  margin: 5px;
  border: solid black 1px;
  border-radius: 2px 2px 2px 2px;
  padding: 5px;
}

.bardbase {
  font-weight: bold;
  font-style: italic;
  font-size: 26px;
}

.credit {
  /*position: fixed;
  right: 20px;
  bottom: 2px;*/
  float: right;
  color: grey;
}
img {
  height: 16px;
  width: 16px;
  padding-left: 3px;
}

/** Button styling **/
button {
  font-family: 'Crimson Text', "Times New Roman", Arial, Helvetica, sans-serif;
  background-color: #efefef;
  border: 1px solid #666666;
  border-radius: 2px;
  padding-left: 5px;
  margin: 0px;
  height: 25px;
}

button.Histories {
  background-color: #ddffdc;
}

button.Tragedies {
  background-color: #ffcdcd;
}

button.Comedies {
  background-color: #d4ebf2;
}

button.Romances {
  background-color: #b5ead7;
}


button:hover {
  background-color: #e0e0e0;
}

button:disabled {
  background-color: #ebebeb;
  border: 1px solid #afafaf;
}

button.selected {
    background: #C0392B; /*C0392B       aab305  4958bf*/
    color: white;
}

button.selected:disabled {
    background: #cc9999;
    color: white;
}

.genres button {
  min-width: 65px;
}